import React from 'react'
import styled from 'styled-components'

import media from 'styles/media'

import aurecCapitalLogo from 'assets/icons/aurec-capital-logo.svg'

const StyledWrapper = styled.div`
  margin: 0 auto;
  max-width: 1400px;
  padding: 20px 20px 0 20px;
  text-align: center;

  ${media.lg.min} {
    padding: 50px 50px 0 50px;
  }
`

const StyledTitle = styled.p`
  font-size: 33px;
`

const StyledLogo = styled.img`
  margin-top: 42px;
  height: 45px;
`

const StyledDescription = styled.p`
  margin-top: 42px;
  line-height: 2.5;

  ${media.lg.min} {
    font-size: 18px;
  }
`

type ShortWhoWeAreProps = {
  title: string
  description: string
}

const ShortWhoWeAre: React.FC<ShortWhoWeAreProps> = ({
  title,
  description,
}) => {
  return (
    <StyledWrapper>
      <StyledTitle>{title}</StyledTitle>

      <StyledLogo src={aurecCapitalLogo} alt="aurec capital logo" />

      <StyledDescription>{description}</StyledDescription>
    </StyledWrapper>
  )
}

export default ShortWhoWeAre
