import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'

import NewsCard from 'components/news-card'

import media from 'styles/media'
import { breakpoints } from 'styles/theme'

import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'

import useBreakpoint from 'hooks/useBreakpoint'

const StyledWrapper = styled.section`
  padding: 0 20px;
  max-width: 1400px;
  margin: 75px auto 0 auto;
  position: relative;

  ${media.lg.min} {
    padding: 0 50px;
  }

  ${media.xl.min} {
    margin-top: 100px;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 740px;
      height: 740px;
      border: 1px solid ${({ theme }) => theme.colors.green};
      border-radius: 50%;
      left: -60%;
      top: -15%;
    }
  }
`

const StyledHeading = styled.h1`
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
  line-height: 55px;

  ${media.lg.min} {
    br {
      display: none;
    }
  }
`

const StyledCardsWrapper = styled.div`
  margin-top: 60px;
  .swiper {
    overflow: visible;
  }

  .swiper-wrapper {
    padding-bottom: 75px;
  }

  .swiper-pagination {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0px !important;
  }

  .swiper-pagination-bullet {
    border: 1px solid ${({ theme }) => theme.colors.orange};
    background-color: ${({ theme }) => theme.colors.white};
    width: 15px;
    height: 15px;

    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.colors.green};
    border: none;
    width: 12px;
    height: 12px;
  }
`

const StyledMobileNewsCardsWrapper = styled.div``

type NewsCardsProps = {
  title: string
}

const NewsCards: React.FC<NewsCardsProps> = ({ title }) => {
  const data = useStaticQuery(graphql`
    query NewsQuery {
      allWpPost {
        edges {
          node {
            date
            id
            slug
            title
            aktualnosci {
              newsSummary
              newsTitle
              newsInvestment
              newsHeroImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [swiper, setSwiper] = useState<SwiperCore | null>(null)

  const { md } = useBreakpoint()

  return (
    <StyledWrapper id="aktualnosci">
      <StyledHeading dangerouslySetInnerHTML={{ __html: title }} />
      {md ? (
        <StyledCardsWrapper>
          <Swiper
            spaceBetween={40}
            slidesPerView={1}
            grabCursor
            modules={[Pagination]}
            pagination={{ clickable: true }}
            onSwiper={(initSwiper) => setSwiper(initSwiper)}
            breakpoints={{
              [breakpoints.md]: {
                slidesPerView: 2,
              },
              [breakpoints.lg]: {
                slidesPerView: 4,
              },
            }}
          >
            {data?.allWpPost?.edges.map((el, index) => {
              return (
                <SwiperSlide key={index}>
                  <NewsCard
                    title={el.node.aktualnosci.newsTitle}
                    slug={el.node.slug}
                    summary={el.node.aktualnosci.newsSummary}
                    date={el.node.date}
                    investment={el.node.aktualnosci.newsInvestment}
                    image={
                      el.node.aktualnosci.newsHeroImage.localFile
                        .childImageSharp.gatsbyImageData
                    }
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </StyledCardsWrapper>
      ) : (
        <StyledMobileNewsCardsWrapper>
          {data?.allWpPost?.edges.map((el, index) => {
            return (
              <NewsCard
                title={el.node.aktualnosci.newsTitle}
                slug={el.node.slug}
                summary={el.node.aktualnosci.newsSummary}
                date={el.node.date}
                investment={el.node.aktualnosci.newsInvestment}
                image={
                  el.node.aktualnosci.newsHeroImage.localFile.childImageSharp
                    .gatsbyImageData
                }
              />
            )
          })}
        </StyledMobileNewsCardsWrapper>
      )}
    </StyledWrapper>
  )
}

export default NewsCards
