import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { Swiper, SwiperSlide } from 'swiper/react'

import media from 'styles/media'

import { GatsbyImage } from 'gatsby-plugin-image'
import { Colors } from 'types/theme'

const StyledWrapper = styled.section`
  margin: 100px 0 0 auto;
`

const StyledTitle = styled.h2`
  font-size: 32px;
  text-align: center;

  ${media.lg.min} {
    font-size: 36px;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 60px 0 0 auto;
  ${media.xl.min} {
    padding: 0 0 0 140px;
  }
`

const InnerWrapper = styled.div`
  position: relative;
  ${media.xl.min} {
    .swiper-slide {
      width: 1040px !important;
    }
  }
`

const SlideContent = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
  /* width: 100%; */
  padding: 110px 15px 20px;
  transform: translateX(0);
  transition: 0.3s;
  margin-top: 330px;
  ${media.xl.min} {
    max-width: 590px;
    padding: 20px 0;
    margin-top: 0;
  }

  h2 {
    margin-top: 20px;
  }
`

const Slide = styled.article<{ active?: boolean }>`
  position: relative;
  width: 100%;
  ${media.xl.min} {
    min-height: 490px;
    ${({ active }) =>
      active &&
      css`
        & > ${SlideContent} {
          transform: translateX(420px);
        }
      `}
  }
`

const Description = styled.p`
  line-height: 2.5;
`

const Text = styled(Description)<{ color: keyof Colors }>`
  margin-top: 30px;
  padding: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme, color }) => theme.colors[color]};
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 315px;
  height: 315px;
  z-index: 2;
  background: #fff;
  pointer-events: none;
  user-select: none;
  ${media.xl.min} {
    left: 0;
    transform: none;
    width: 380px;
    height: 380px;
  }
`

const ImageInnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 50%;
    border: 2px solid ${({ color }) => color};
    transition: 0.3s;
    ${media.xl.min} {
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      transform: translate(20px, -20px);
    }
  }
`

const SlideImage = styled.div<{ active?: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border-radius: 50%;
  overflow: hidden;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const StyledGatsbyImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: 100%;
  & > img {
    object-fit: cover;
    object-position: 0% 0%;
  }
`

const SlideBar = styled.figure`
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  height: 4px;
  background: #e8e5e5;
  ${media.xl.min} {
    top: auto;
    bottom: 20px;
  }
`

const SlideBarInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const DotWrapper = styled.div<{ color: keyof Colors }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  background: ${({ theme, color }) => theme.colors[color]};
  ${media.xl.min} {
    width: 380px;
  }
  & > div {
    background: ${({ theme, color }) => theme.colors[color]};
  }
`

const Dot = styled.div<{ active?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
  transition: 0.3s;
  ${media.xl.min} {
    left: ${({ active }) => (active ? '50%' : '0')};
  }
`

const ArrowsWrapper = styled.div`
  position: absolute;
  top: 30px;
  left: 15px;
  right: 15px;
  display: flex;
  justify-content: space-between;
  z-index: 5;
  ${media.sm.max} {
    top: 350px;
  }
  ${media.xl.min} {
    flex-direction: column;
    top: 30%;
    left: -110px;
    right: auto;
    transform: translateY(-30%);
  }
`

const ArrowButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  width: 60px;
  height: 60px;
  margin: 10px 0;
  border-radius: 50%;
  cursor: pointer;
  background: #fff;
  transition: 0.3s;
  border: 1px solid ${({ theme }) => theme.colors.orange};
  ${media.sm.min} {
    width: 75px;
    height: 75px;
  }
  @media (hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.colors.orange};
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }
`

const settings = {
  grabCursor: true,
  slidesPerView: 'auto',
  loop: true,
}

const AdvantagesCarousel = ({ advantages, title }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const swiperRef = useRef(null)

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }
  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  return (
    <StyledWrapper>
      <StyledTitle>{title}</StyledTitle>
      <Wrapper>
        <InnerWrapper>
          <ArrowsWrapper>
            <ArrowButton onClick={goPrev} aria-label="poprzedni slajd">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="20.001"
                viewBox="0 0 11 20.001"
              >
                <path
                  id="arrow"
                  d="M259.633,6538.71a1.062,1.062,0,0,1-1.464,0l-8.563-8.264a1.951,1.951,0,0,1,0-2.828l8.625-8.325a1.063,1.063,0,0,1,1.454-.01.976.976,0,0,1,.011,1.425l-7.894,7.617a.975.975,0,0,0,0,1.414l7.831,7.558a.974.974,0,0,1,0,1.413"
                  transform="translate(-248.999 -6519.001)"
                  fill="#ff9d00"
                  fill-rule="evenodd"
                />
              </svg>
            </ArrowButton>
            <ArrowButton onClick={goNext} aria-label="następny slajd">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="20.001"
                viewBox="0 0 11 20.001"
              >
                <path
                  id="arrow"
                  d="M249.366,6538.71a1.062,1.062,0,0,0,1.464,0l8.563-8.264a1.951,1.951,0,0,0,0-2.828l-8.625-8.325a1.063,1.063,0,0,0-1.454-.01.976.976,0,0,0-.011,1.425l7.894,7.617a.975.975,0,0,1,0,1.414l-7.831,7.558a.974.974,0,0,0,0,1.413"
                  transform="translate(-249 -6519.001)"
                  fill="#ff9d00"
                  fill-rule="evenodd"
                />
              </svg>
            </ArrowButton>
          </ArrowsWrapper>
          <ImageWrapper>
            <ImageInnerWrapper color="orange">
              {advantages.map(({ whoWeAreAdvantagesImgSingle }, index) => (
                <SlideImage active={index === activeIndex} key={index}>
                  <StyledGatsbyImage
                    image={
                      whoWeAreAdvantagesImgSingle?.localFile?.childImageSharp
                        ?.gatsbyImageData!
                    }
                    alt={whoWeAreAdvantagesImgSingle?.altText}
                  />
                </SlideImage>
              ))}
            </ImageInnerWrapper>
          </ImageWrapper>
          <Swiper
            ref={swiperRef}
            onSlideChange={({ realIndex }) => setActiveIndex(realIndex)}
            {...settings}
          >
            {advantages.map(
              (
                {
                  whoWeAreAdvantagesTitleSingle,
                  whoWeAreAdvantagesDescriptionSingle,
                  whoWeAreAdvantagesClaimSingle,
                },
                index
              ) => (
                <SwiperSlide key={index}>
                  <Slide active={index === activeIndex}>
                    <SlideContent>
                      <h2>{whoWeAreAdvantagesTitleSingle}</h2>
                      <Description>
                        {whoWeAreAdvantagesDescriptionSingle}
                      </Description>
                      <Text
                        color={`${
                          index === 0
                            ? 'green'
                            : index === 1
                            ? 'blue'
                            : 'orange'
                        }`}
                      >
                        {whoWeAreAdvantagesClaimSingle}
                      </Text>
                    </SlideContent>
                    <SlideBar>
                      <SlideBarInner>
                        <DotWrapper
                          color={`${
                            index === 0
                              ? 'green'
                              : index === 1
                              ? 'blue'
                              : 'orange'
                          }`}
                        >
                          <Dot active={index === activeIndex}>{index + 1}</Dot>
                        </DotWrapper>
                      </SlideBarInner>
                    </SlideBar>
                  </Slide>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </InnerWrapper>
      </Wrapper>
    </StyledWrapper>
  )
}

export default AdvantagesCarousel
