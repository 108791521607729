import React from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/layout'

import Header from 'components/sections/main-header'

import AdvantagesCarousel from 'components/sections/advantages-carousel'
import FlatsForSale from 'components/sections/flats-for-sale'

import type { KimJestesmyPageQuery } from 'types/graphql'
import ShortWhoWeAre from 'components/sections/short-who-we-are'
import Experience from 'components/sections/experience'
import NewsCards from 'components/sections/news-cards'
// import SlideGallery from 'components/sections/slide-gallery'
import Seo from 'components/layout/seo'
import AskAboutFlats from 'components/ask-about-flats'

const KimJestesmy: React.FC<PageProps<KimJestesmyPageQuery>> = ({ data }) => {
  const PAGE = data?.wpPage?.kimJestesmy

  const HERO_IMAGE = {
    src: PAGE?.whoWeAreImg?.localFile?.childImageSharp?.gatsbyImageData!,
    alt: PAGE?.whoWeAreImg?.altText!,
  }

  return (
    <Layout>
      <Seo
        title={data?.wpPage?.seo?.title!}
        description={data?.wpPage?.seo?.metaDesc!}
        ogTitle={data?.wpPage?.seo?.opengraphTitle!}
        ogDescription={data?.wpPage?.seo?.opengraphDescription!}
        ogImage={data?.wpPage?.seo?.opengraphImage?.sourceUrl!}
      />
      <AskAboutFlats />
      <Header
        title={PAGE?.whoWeAreTitle!}
        image={HERO_IMAGE}
        buttonText="Dowiedz się więcej"
      />

      <ShortWhoWeAre
        title={PAGE?.whoWeAreAboutTitle!}
        description={PAGE?.whoWeAreAboutDescription!}
      />

      <Experience
        title={PAGE?.whoWeAreExperienceTitle!}
        description={PAGE?.whoWeAreExperienceDescription!}
        image={{
          src: PAGE?.whoWeAreExperienceImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.whoWeAreExperienceImg?.altText!,
        }}
      />

      <NewsCards title="Aktualności<br/> o Aurec Home" />

      <AdvantagesCarousel advantages={PAGE?.whoWeAreAdvantages} />

      <FlatsForSale investments={PAGE?.whoWeAreInvestments} />

      {/* <SlideGallery /> */}
    </Layout>
  )
}

export default KimJestesmy

export const query = graphql`
  query KimJestesmyPage {
    wpPage(slug: { regex: "/kim-jestesmy/" }) {
      id
      kimJestesmy {
        whoWeAreAboutDescription
        whoWeAreAboutTitle
        whoWeAreAdvantagesTitle
        whoWeAreExperienceDescription
        whoWeAreExperienceTitle
        whoWeAreInvestmentsTitle
        whoWeAreTitle
        whoWeAreAdvantages {
          whoWeAreAdvantagesClaimSingle
          whoWeAreAdvantagesDescriptionSingle
          whoWeAreAdvantagesTitleSingle
          whoWeAreAdvantagesImgSingle {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
              }
            }
            altText
          }
        }
        whoWeAreExperienceImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
          altText
        }
        whoWeAreImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
          altText
        }
        whoWeAreInvestments {
          whoWeAreInvestmentsSingle
          whoWeAreInvestmentsImgSingle {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
              }
            }
            altText
          }
          whoWeAreInvestmentsLinkSingle
        }
      }
      seo {
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`
