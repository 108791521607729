import React, { useState } from 'react'
import styled from 'styled-components'
import SwiperInstance from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import { GatsbyImage } from 'gatsby-plugin-image'

const StyledWrapper = styled.section`
  max-width: 1400px;
  margin: 60px auto;
  padding: 0 20px;

  ${media.lg.min} {
    margin-top: 100px;
    padding: 0;
  }
`

const StyledTitle = styled.h2`
  font-size: 32px;
  text-align: center;

  ${media.xl.min} {
    font-size: 36px;
  }
`

const StyledSwiperWrapper = styled.div`
  margin-top: 60px;

  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  height: 550px;

  padding-left: 20px;
  padding-right: 20px;

  .swiper {
    margin: 0 auto;

    overflow: visible;
    padding-bottom: 55px;
  }

  .swiper-wrapper {
    padding-bottom: 0;
  }

  .swiper-pagination {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0px !important;
  }

  .swiper-pagination-bullet {
    border: 1px solid ${({ theme }) => theme.colors.orange};
    background-color: ${({ theme }) => theme.colors.white};
    width: 15px;
    height: 15px;

    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.colors.green};
    border: none;
    width: 12px;
    height: 12px;
  }

  .swiper-slide {
    max-width: 460px !important;
    max-width: 460px !important;
    display: flex;
    justify-content: center;
    opacity: 0.3;
    transition: 0.3s;
  }

  .swiper-slide-active,
  .swiper-slide-next {
    opacity: 1;
  }

  .swiper-button-prev,
  .swiper-button-next {
    margin-top: 0;
    top: 200px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.orange};
    width: 65px;
    height: 65px;
    border-radius: 50%;
    transition: 0.3s;

    &:after {
      color: ${({ theme }) => theme.colors.orange};
      font-size: 20px;
      font-weight: bold;
      transition: 0.3s;
    }
  }

  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    background-color: ${({ theme }) => theme.colors.orange};

    &:after {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

const StyledSwiperSlideContentWrapper = styled.div`
  margin: 0;
  display: flex;
  height: 460px;
  width: 460px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  ${media.lg.max} {
    height: auto;
    width: auto;
    margin-top: 40px;
    padding-top: 90px;
    padding-bottom: 80px;
  }
`

const StyledLocation = styled.p`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;

  ${media.lg.max} {
    max-width: 80%;
    text-align: center;
    line-height: 1.5;
  }
`

const StyledButton = styled.button`
  box-sizing: border-box;
  display: block;
  margin-top: 40px;
  background-color: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-weight: 500;
  text-decoration: none;
  padding: 16px 20px;
  border: none;
  border-radius: 34px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: ${({ theme }) => `2px solid ${theme.colors.green}`};
  transition: 0.3s;
  width: 100%;
  max-width: 200px;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`
const StyledGatsbyImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.85);

  z-index: -1;

  overflow: hidden;

  ${media.lg.max} {
    max-height: calc(100vw - 40px);
  }

  img {
    border-radius: 29px;
  }
`

const StyledInvestmentCardWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vw - 40px);
  margin-bottom: 20px;
`

type FlatsForSaleProps = {
  investments: Array<{
    whoWeAreInvestmentsImgSingle: any
    whoWeAreInvestmentsSingle: string
    whoWeAreInvestmentsLinkSingle: string
  }>
}

const FlatsForSale: React.FC<FlatsForSaleProps> = ({ investments }) => {
  const [swiper, setSwiper] = useState<SwiperInstance | null>(null)

  const { lg } = useBreakpoint()

  return (
    <StyledWrapper>
      <StyledTitle>Nasze mieszkania na sprzedaż</StyledTitle>
      {lg ? (
        <StyledSwiperWrapper>
          <Swiper
            slidesPerView="auto"
            grabCursor
            mousewheel={{ releaseOnEdges: true }}
            onSwiper={(initSwiper: SwiperInstance) => setSwiper(initSwiper)}
            loop
            spaceBetween={40}
            modules={[Navigation, Pagination]}
            pagination={{ clickable: true }}
            navigation
          >
            {investments.map((el, index: number) => {
              return (
                <SwiperSlide key={index}>
                  <StyledSwiperSlideContentWrapper>
                    <StyledGatsbyImage
                      image={
                        el.whoWeAreInvestmentsImgSingle.localFile
                          ?.childImageSharp?.gatsbyImageData!
                      }
                      alt={el.whoWeAreInvestmentsImgSingle?.altText}
                    />
                    <StyledLocation>
                      {el.whoWeAreInvestmentsSingle}
                    </StyledLocation>
                    <StyledButton
                      as="a"
                      href={el.whoWeAreInvestmentsLinkSingle}
                    >
                      Zobacz więcej
                    </StyledButton>
                  </StyledSwiperSlideContentWrapper>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </StyledSwiperWrapper>
      ) : (
        <>
          {investments.map((el, index: number) => {
            return (
              <StyledInvestmentCardWrapper key={index}>
                <StyledGatsbyImage
                  image={
                    el.whoWeAreInvestmentsImgSingle.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt={el.whoWeAreInvestmentsImgSingle?.altText}
                />
                <StyledLocation>{el.whoWeAreInvestmentsSingle}</StyledLocation>
                <StyledButton as="a" href={el.whoWeAreInvestmentsLinkSingle}>
                  Zobacz więcej
                </StyledButton>
              </StyledInvestmentCardWrapper>
            )
          })}
        </>
      )}
    </StyledWrapper>
  )
}

export default FlatsForSale
