import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import media from 'styles/media'

const StyledWrapper = styled.div`
  margin: 0 auto;
  max-width: 1400px;
  padding: 55px 20px 0 20px;

  display: flex;
  flex-direction: column;

  text-align: center;

  ${media.lg.min} {
    padding: 80px 50px 0 50px;

    flex-direction: row;
    gap: 50px;

    text-align: left;
  }
`
const StyledImageWrapper = styled.div`
  ${media.lg.min} {
    flex-basis: 50%;
  }
`

const StyledContentWrapper = styled.div`
  ${media.lg.min} {
    flex-basis: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  mask-image: linear-gradient(black 30%, transparent 90%);

  ${media.lg.min} {
    mask-image: none;
  }
`
const StyledTitle = styled.p`
  margin-top: 30px;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.green};
  font-weight: bold;
`
const StyledDescription = styled.p`
  margin-top: 42px;
  line-height: 2.5;

  ${media.lg.min} {
    font-size: 18px;
  }
`

type ExperienceProps = {
  title: string
  description: string
  image: {
    src: IGatsbyImageData
    alt: string
  }
}

const Experience: React.FC<ExperienceProps> = ({
  title,
  description,
  image,
}) => {
  return (
    <StyledWrapper>
      <StyledImageWrapper>
        <StyledGatsbyImage image={image.src} alt={image.alt} />
      </StyledImageWrapper>
      <StyledContentWrapper>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
      </StyledContentWrapper>
    </StyledWrapper>
  )
}

export default Experience
