import React, { useRef } from 'react'
import styled from 'styled-components'
import { Link, navigate } from 'gatsby'

import media from 'styles/media'

import arrowSimpleRight from 'assets/icons/arrow-simple-right.svg'

import useOutsideClick from 'hooks/useOutsideClick'
import useBreakpoint from 'hooks/useBreakpoint'

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

const StyledGatsbyImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  height: 100%;
  transition: 0.3s;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 1;
    /* background: linear-gradient(
      to top,
      white,
      rgba(255, 255, 255, 0.45),
      white
    ); */
  }

  & > img {
    border-radius: 29px;
    object-fit: cover;
    object-position: 0% 0%;
  }
`

const StyledWrapper = styled.div`
  position: relative;
  margin: 0;
  height: 300px;
  background-color: ${({ theme }) => theme.colors.green};
  border-radius: 29px;
  color: white;
  cursor: pointer;

  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 50px;

  flex-shrink: 0;

  ${media.md.max} {
    margin: 60px 0;
    height: 350px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 27px;
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.1), white); */
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &:hover::before,
  &:hover::after {
    opacity: 0;
    transition: 0.3s;
  }
  &:hover button {
    height: auto;
    width: fit-content;
    border-radius: 29px;
    padding: 16px 20px;
    transition: 0.3s;
    border: 1px solid ${({ theme }) => theme.colors.green};
  }

  &:hover button img {
    display: none;
  }

  &:hover button span {
    position: static;
    transform: scale(1);
    transition: 0.3s transform;
  }

  &:hover ${StyledGatsbyImage} {
    opacity: 0;
  }
`

const StyledButton = styled.button`
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  border: #eef5ed;
  height: 65px;
  width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;

  & span {
    position: absolute;
    transform: scale(0);
  }
`

const StyledDate = styled.p`
  font-size: 14px;
`
const StyledLocation = styled.p`
  margin-top: 20px;
  font-size: 14px;
`
const StyledEventName = styled.p`
  line-height: 28px;
  margin-top: 25px;
  font-size: 24px;
  font-weight: bold;
`
const StyledSummary = styled.p`
  padding-top: 40px;
  padding-bottom: 20px;
  line-height: 1.5;
`

type NewsCardProps = {
  title: string
  slug: string
  summary: string
  date: string
  investment: string
  image: IGatsbyImageData
}

const NewsCard: React.FC<NewsCardProps> = ({
  title,
  slug,
  summary,
  date,
  investment,
  image,
}) => {
  const wrapperRef = useRef(null)

  const clickCount = useRef(0)

  const resetCount = () => {
    clickCount.current = 0
  }
  useOutsideClick({ ref: wrapperRef, handler: resetCount })

  const { lg } = useBreakpoint()

  const goToEvent = () => {
    if (lg) {
      return navigate(`/aktualnosci/${slug}`)
    } else {
      clickCount.current++
      if (clickCount.current > 1) {
        navigate(`/aktualnosci/${slug}`)
      }
    }
  }

  return (
    <StyledWrapper onClick={() => goToEvent()} ref={wrapperRef}>
      <StyledGatsbyImage image={image} alt="news image" />
      <StyledDate>
        {date.split('T')[0].split('-').reverse().join('.')}
      </StyledDate>
      <StyledLocation>{investment}</StyledLocation>
      <StyledEventName>{title}</StyledEventName>
      <StyledSummary>{summary}</StyledSummary>
      <StyledButton>
        <img src={arrowSimpleRight} alt="" />
        <span>Zobacz</span>
      </StyledButton>
    </StyledWrapper>
  )
}

export default NewsCard
